import { i18nConfig } from "app/i18n/config";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import Backend, { type HttpBackendOptions } from "i18next-http-backend";
import { getInitialNamespaces } from "remix-i18next/client";
import { ReactNode } from "react";

export async function hydrateI18n() {
  await i18next
    .use(initReactI18next)
    .use(Backend)
    .init<HttpBackendOptions>({
      ...i18nConfig,
      ns: getInitialNamespaces(),
      initImmediate: false,
      backend: {
        loadPath: "/locales/{{lng}}.json",
        requestOptions: { cache: "no-store" },
      },
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ["htmlTag"],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    });
}

export const I18nClientProvider = ({ children }: { children: ReactNode }) => (
  <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
);
